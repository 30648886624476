import { lazy, Suspense } from 'react';
import { Route, Routes } from 'react-router';
import './App.css';

const Home = lazy(() => import('../pages/Home/Home'));
const NotFound = lazy(() => import('../pages/NotFound/NotFound'));
const PrivacyPolicy = lazy(() => import('../pages/PrivacyPolicy/PrivacyPolicy'));
const BlockedSubscription = lazy(() => import('../pages/Subscription/BlockedSubscription'));
const CompletedSubscription = lazy(() => import('../pages/Subscription/CompletedSubscription'));
const RequestedSubscription = lazy(() => import('../pages/Subscription/RequestedSubscription'));
const SubscriptionError = lazy(() => import('../pages/Subscription/SubscriptionError'));
const Verify = lazy(() => import('../pages/Verify/Verify'));
const VerifyError = lazy(() => import('../pages/Verify/VerifyError'));
const VerifyTempError = lazy(() => import('../pages/Verify/VerifyTempError'));
const Unsubscribe = lazy(() => import('../pages/Unsubscribe/Unsubscribe'));
const CompletedUnsubscription = lazy(() => import('../pages/Unsubscribe/CompletedUnsubscription'));
const UnsubscriptionError = lazy(() => import('../pages/Unsubscribe/UnsubscriptionError'));

function App() {
  const year = new Date().getFullYear();
  return (
    <>
      <header>
        <img src="/images/logotype.png" className="logo" alt="Bodega de Lopez" width="180" height="180" />
      </header>
      <main>
        <Suspense fallback={<></>}>
          <Routes>
            <Route exact path="/" element={<Home />} />
            <Route path="/iscrizione-richiesta" element={<RequestedSubscription />} />
            <Route path="/iscrizione-richiesta/:email" element={<RequestedSubscription />} />
            <Route path="/iscrizione-bloccata" element={<BlockedSubscription />} />
            <Route path="/iscrizione-bloccata/:email" element={<BlockedSubscription />} />
            <Route path="/iscrizione-completata" element={<CompletedSubscription />} />
            <Route path="/errore-iscrizione" element={<SubscriptionError />} />
            <Route path="/verifica" element={<Verify />} />
            <Route path="/verifica/:hash" element={<Verify />} />
            <Route path="/errore-verifica" element={<VerifyError />} />
            <Route path="/errore-temporaneo-verifica" element={<VerifyTempError />} />
            <Route path="/disiscrizione" element={<Unsubscribe />} />
            <Route path="/disiscrizione/:email" element={<Unsubscribe />} />
            <Route path="/disiscrizione-completata" element={<CompletedUnsubscription />} />
            <Route path="/disiscrizione-completata/:email" element={<CompletedUnsubscription />} />
            <Route path="/errore-disiscrizione" element={<UnsubscriptionError />} />
            <Route path="/privacy-policy" element={<PrivacyPolicy />} />
            <Route path="*" element={<NotFound />} />
          </Routes>
        </Suspense>
      </main>
      <footer>
        &copy; {year} Bodega de Lopez di Lopez Valdes Legna - Via Lorenzo Delleani 23 - 13900 Biella (BI) - P.IVA: 02623480023
      </footer>
    </>
  );
}

export default App;
